import { Box, Button } from "@mui/material"
import { useEffect } from "react"
import { ProductGroupsViewType } from "../app/ProductGroups"
import { ProductGroup } from "../types/product"

const blankImage = require('./images/blank400300.png')

const ProductGroupsView: ProductGroupsViewType = ({ list, total, onClick, theme, onClickPayment, title, paymentButtonLabel, isReserve, isTableOrder, isMenu }) => {
    useEffect(() => {
        window.document.title = title
    }, [title])
    const renderImage = (item: ProductGroup) => {
        if (item.image == null) {
            return (
                <Box
                    component="img"
                    src={blankImage}
                    alt=""
                />
            )
        }
        return (
            <Box
                component="img"
                src={blankImage}
                sx={{
                    background: `url(${item.image.url}) center center no-repeat`,
                    backgroundSize: 'cover',
                }}
                alt=""
            ></Box>
        )
    }
    return (
        <section className="menu_list ">
            <div className="menu_block">
                <h2>{title}</h2>

                <ul className="list category">
                    {list.map(item => (
                        <li key={item.id} onClick={() => onClick(item)}>
                            {renderImage(item)}
                            <span className="menu_category">{item.label}</span>
                        </li>
                    ))}
                </ul>
                {!isMenu && (
                    <div>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => onClickPayment()}>{paymentButtonLabel}</Button>
                    </div>
                )}
            </div>
        </section>
    )
}

export default ProductGroupsView

