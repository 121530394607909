import { Box } from "@mui/material";
import { StampNumPadViewType } from "../app/StampNumPad";

const NumPadView: StampNumPadViewType = ({ onAddNumber, onCancel, onDeleteTail, onSubmit, pin }) => {
    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            paddingTop: '64px',
            background: 'white',
        }}>
            <Box sx={{
                background: 'white',
                lineHeight: '12vw',
                height: '12vw',
                fontSize: '10vw',
                textAlign: 'center',
                letterSpacing: '4vw',
            }}>{pin}</Box>
            <Box sx={{
                width: '100%',
                paddingLeft: '7vw',
                paddingRight: '7vw',
            }}>
                <Box sx={buttonStyle} onClick={() => onAddNumber("1")}><Box sx={numStyle}>1</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("2")}><Box sx={numStyle}>2</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("3")}><Box sx={numStyle}>3</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("4")}><Box sx={numStyle}>4</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("5")}><Box sx={numStyle}>5</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("6")}><Box sx={numStyle}>6</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("7")}><Box sx={numStyle}>7</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("8")}><Box sx={numStyle}>8</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("9")}><Box sx={numStyle}>9</Box></Box>
                <Box sx={buttonStyle} onClick={() => onDeleteTail()}><Box sx={{ ...numStyle, fontSize: "10vw" }}>←</Box></Box>
                <Box sx={buttonStyle} onClick={() => onAddNumber("0")}><Box sx={numStyle}>0</Box></Box>
                <Box sx={buttonStyle} onClick={() => onSubmit()}><Box sx={{ ...numStyle, fontSize: "6vw" }}>送信</Box></Box>
            </Box>
        </Box>
    )
}

export default NumPadView

const buttonStyle = {
    display: 'inline-block',
    position: 'relative',
    boxSizing: 'border-box',
    border: 'solid 1px rgba(0,0,0,0.8)',
    borderRadius: '5px',
    width: '30%',
    marginLeft: '2%',
    marginTop: '1vw',
    paddingTop: '30%',
}

const numStyle={
    display: 'inline-block',
    position: 'absolute',
    fontSize: '10vw',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
}