import { ComponentType, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Product } from "../types/product"
import { useReserve } from "./providers/reserve"
import { useServer } from "./providers/server"
import { ProductDetailViewType } from "./ProductDetail"

const ReserveProductDetail:ComponentType<Props> = ({View})=> {
    const {productId} = useParams()
    const server = useServer()
    const [product, setProduct] = useState<Product|null>(null)
    const [isSendComplete, setIsSendComplete] = useState(false)
    const {addToCart} = useReserve()

    const getProductAsync = useCallback(async () => {
        const product = await server.getProduct(productId!)
        setProduct(product)
    }, [productId, server])
    useEffect(() => {
        getProductAsync()
    }, [getProductAsync])
    const [numOrder, setNumOrder] = useState(0)
    const handleDecrementNumOrder = () => setNumOrder(numOrder => {
        if (numOrder <= 0) {
            return 0
        }
        return numOrder - 1
    })
    const handleOrder = async () => {
        await addToCart(product!, numOrder)
        setIsSendComplete(true)
        setNumOrder(0)
    }

    const handleClearIsSendComplete = () => {
        setIsSendComplete(false)
    }
    if (product == null) {
        return <></>
    }
    return (
        <View
            product={product!}
            numOrder={numOrder}
            onIncrementNumOrder={() => setNumOrder(numOrder => numOrder+1)}
            onDecrementNumOrder={handleDecrementNumOrder}
            disableOrderButton={numOrder <= 0}
            disableDecrementButton={numOrder <= 0}
            onSend={handleOrder}
            isSendComplete={isSendComplete}
            onClearIsSendComplete={handleClearIsSendComplete}
            isReserve={true}
            isTableOrder={false}
            isMenu={false}
        />
    )
}

export default ReserveProductDetail

/*
export type ProductDetailViewType = ComponentType<{
    product: Product
    numOrder:number
    onIncrementNumOrder: () => any
    onDecrementNumOrder: () => any
    disableOrderButton: boolean
    disableDecrementButton: boolean
    onSend: () => any
    isSendComplete: boolean
    onClearIsSendComplete: () => any
    isReserve: boolean
}>
*/

interface Props {
    View: ProductDetailViewType
}