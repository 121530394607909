import { ComponentType, useCallback, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { StampGift } from "../types/stampGift"
import { useServer } from "./providers/server"

const StampGiftDetail: ComponentType<Props> = ({ View }) => {
    const params = useParams()
    const server = useServer()
    const navigate = useNavigate()
    const fetchAsync = useCallback(async () => {
        setItem(await server.getStampGift(params.stampGiftId!))
    }, [params.stampGiftId, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const [item, setItem] = useState<StampGift>({
        id: "",
        name: "",
        point: 0,
    })
    useEffect(() => {
        if (item.id !== "") {
            window.document.title = item.name
        }
    }, [item.id, item.name])
    const [exchanged, setExchanged] = useState(false)
    const [handleClickExchange, submittingExchange] = useOnClick(async () => {
        try {
            if (!window.confirm("景品と交換してもよろしいですか？")) {
                return
            }
            await server.exchangeStampGift(params.stampGiftId!)
            setExchanged(true)
        } catch (error) {
            alert('ポイントが足りていません')
        }
    })
    return (
        <View
            item={item}
            isLoading={item.id === ""}
            onClickExchange={handleClickExchange}
            exchangeDisabled={submittingExchange || item.id === ""}
            exchanged={exchanged}
        />
    )
}

export default StampGiftDetail

interface Props {
    View: StampGiftDetailViewType
}

export type StampGiftDetailViewType = ComponentType<{
    item: StampGift
    isLoading: boolean
    onClickExchange: () => any
    exchanged: boolean
    exchangeDisabled: boolean
}>

const useOnClick = (f: () => Promise<any>): [() => any, boolean] => {
    const [submitting, setSubmitting] = useState(false)
    return [
        async () => {
            try {
                setSubmitting(true)
                await f()
            } finally {
                setSubmitting(false)
            }
        },
        submitting,
    ]
}
