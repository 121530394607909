import { ComponentType, useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Product, ProductGroup, ProductsType } from "../types/product"
import { SearchResult } from '../types/search'
import { Theme } from "../types/theme"
import { useServer } from "./providers/server"
import { useTheme } from "./providers/theme"

const ProductGroups: ComponentType<Props> = ({ View, productsType }) => {
    const seatId = useParams().seatId!
    const server = useServer()
    const theme = useTheme()
    const navigate = useNavigate()
    const [{ list, total }, setResult] = useState<SearchResult<ProductGroup>>({ list: [], total: 0 })
    const fetchAsync = useCallback(async () => {
        setResult(await server.searchProductGroups({ page: 1, perPage: 10 }))
    }, [server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const handleClick = (item: ProductGroup) => {
        if (productsType === "tableOrder") {
            navigate(`/seat=${seatId}/products/group=${item.id}`)
        } else {
            navigate(`/products/group=${item.id}`)
        }
    }
    const title = useMemo(() => {
        if (productsType === "menu") {
            return "メニュー"
        }
        return "テーブルオーダー"
    }, [productsType])
    return (
        <View
            list={list}
            total={total}
            onClick={handleClick}
            theme={theme}
            onClickPayment={() => {
                navigate(`/seat=${seatId}/table-order-payment`)
            }}
            paymentButtonLabel="お会計"
            title={title}
            isReserve={productsType === "reserve"}
            isMenu={productsType === "menu"}
            isTableOrder={productsType === "tableOrder"}
        />
    )
}

export default ProductGroups

export type ProductGroupsViewType = ComponentType<{
    list: ProductGroup[]
    total: number
    onClick: (item: ProductGroup) => any
    theme: Theme
    paymentButtonLabel: string
    onClickPayment: () => any
    title: string
    isReserve: boolean
    isMenu: boolean
    isTableOrder: boolean
}>

interface Props {
    productsType: ProductsType
    View: ProductGroupsViewType
}
