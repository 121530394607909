import { ComponentType, useState } from "react";

const StampNumPad: ComponentType<Props> = ({View, onSubmit, onCancel}) => {
    const [pin, setPin] = useState("")
    return (
        <View
            onAddNumber={n => setPin(pin => {
                if (pin.length >= 8) {
                    return pin
                }
                return `${pin}${n}`
            })}
            onDeleteTail={() => setPin(pin => {
                if (pin === "") {
                    return pin
                }
                return pin.substring(0, pin.length - 1)
            })}
            onSubmit={() => onSubmit(pin) }
            onCancel={() => onCancel()}
            pin={pin}
        />
    )
}

interface Props {
    View: StampNumPadViewType
    onSubmit: (pin:string) => any
    onCancel: () => any
}

export default StampNumPad

export type StampNumPadViewType = ComponentType<{
    onAddNumber: (n: string) => any
    onDeleteTail: () => any
    onSubmit: () => any
    onCancel: () => any
    pin: string
}>
